export const menuList: any[] = [
  {
    index: 1,
    label: 'Главная страница',
    customIcon: 'icon-home-menu',
    to: '/',
    langKey: 'main'
  },
  {
    label: 'Активные процессы',
    langKey: 'active',
    head: true
  },
  {
    index: 2,
    label: 'Фонд скважин',
    customIcon: 'icon-well-menu',
    icon: '',
    to: '/monitoring',
    disabled: false,
    langKey: 'fond-well'
  },
  {
    index: 3,
    label: 'Ковер бурения',
    primeIcon: 'pi pi-calendar',
    to: '/scheduler',
    disabled: false,
    langKey: 'carp-drilling'
  },
  {
    index: 4,
    label: 'Аналитика',
    customIcon: 'icon-chart-arr-menu',
    to: '/w4',
    disabled: true,
    langKey: 'analytics'
  },
  {
    index: 5,
    label: 'Сводки',
    customIcon: 'icon-clipboard-arr-menu',
    to: '/w5',
    disabled: true,
    langKey: 'summaries'
  },
  {
    label: 'Базы данных',
    langKey: 'bd',
    head: true
  },
  {
    index: 6,
    label: 'Осложнения',
    primeIcon: 'pi pi-exclamation-circle',
    to: '/w6',
    disabled: true,
    langKey: 'complications'
  },
  {
    index: 7,
    label: 'Инклинометрия',
    customIcon: 'icon-inclinometry-menu',
    to: '/w7',
    disabled: true,
    langKey: 'inclinometry'
  },
  {
    index: 8,
    label: 'Паспорта скважин',
    customIcon: 'icon-pass-menu',
    to: '/w8',
    disabled: true,
    langKey: 'pass-well'
  },
  {
    index: 9,
    label: 'Общая аналитика',
    customIcon: 'icon-chart-menu',
    to: '/w9',
    disabled: true,
    langKey: 'general-analytics'
  },
  {
    index: 10,
    label: 'Все сводки',
    customIcon: 'icon-clipboard-filled-menu',
    to: '/w10',
    disabled: true,
    langKey: 'all-summaries'
  },
  {
    index: 11,
    label: 'Справочники',
    customIcon: 'icon-book-menu',
    langKey: 'reference',
    items: [
      {
        index: 11.1,
        label: 'Забойное оборудование',
        to: '/directory-equipment',
        disabled: false,
        langKey: 'directory-equipment'
      },
      {
        index: 11.2,
        label: 'Руководящие документы',
        to: '/w12',
        disabled: true,
        langKey: 'docs'
      },
      {
        index: 11.3,
        label: 'Инструкции',
        to: '/w13',
        disabled: true,
        langKey: 'instruction'
      },
      {
        index: 11.4,
        label: 'Справочник инженера по бурению',
        to: '/w14',
        disabled: true,
        langKey: 'handbook-drill'
      }
    ]
  },
  {
    label: 'Проектная деятельность',
    langKey: 'project-activity',
    head: true
  },
  {
    index: 15,
    label: 'Проектная документация',
    customIcon: 'icon-clipboard-edit-menu',
    langKey: 'project-doc',
    to: '/projdoc'
  },
  {
    index: 31,
    label: 'ИИ для программы бурения',
    primeIcon: 'pi pi-server',
    langKey: 'artificial-intelligence',
    to: '/artificial-intelligence'
  },
  {
    index: 16,
    label: 'Технические задания',
    customIcon: 'icon-file-menu',
    langKey: 'terms',
    items: [
      {
        index: 17,
        label: 'Макет ТЗ',
        to: '/w16',
        disabled: true,
        langKey: 'model'
      },
      {
        index: 18,
        label: 'Ожидают согласования',
        to: '/w17',
        disabled: true,
        langKey: 'waiting'
      },
      {
        index: 19,
        label: 'Согласованные',
        to: '/w18',
        disabled: true,
        langKey: 'agreed'
      }
    ]
  },
  {
    index: 20,
    label: 'Проекты на согласование',
    customIcon: 'icon-check-circle-menu',
    to: '/w19',
    disabled: true,
    langKey: 'projects-on'
  },
  {
    index: 21,
    label: 'Инженерные расчеты',
    customIcon: 'icon-function-menu',
    langKey: 'calculation',
    items: [
      {
        index: 22,
        label: 'Крепление',
        to: '/fastening',
        disabled: false,
        langKey: 'fastening'
      },
      {
        index: 23,
        label: 'Гидравлика',
        to: '/hydraulics',
        disabled: false,
        langKey: 'hydr'
      },
      {
        index: 24,
        label: 'Траектория',
        to: 'calculations/trajectory',
        disabled: false,
        langKey: 'trajec'
      },
      {
        index: 29,
        label: 'Анализ траектории',
        to: 'calculations/analysis',
        disabled: false,
        langKey: 'traj-analysis'
      },
      {
        index: 30,
        label: 'Натяжения и моменты',
        to: '/tensions',
        disabled: false,
        langKey: 'tensions'
      },
      {
        index: 25,
        label: 'Буровые растворы',
        to: '/w24',
        disabled: true,
        langKey: 'drill-fluids'
      },
      {
        index: 26,
        label: 'Смета',
        to: '/w25',
        disabled: true,
        langKey: 'estimate'
      }
    ]
  }
];

export const bottomMenuList: any[] = [
  {
    index: 27,
    label: 'Настройки',
    customIcon: 'icon-settings-menu',
    to: '/w26',
    disabled: true,
    langKey: 'settings'
  },
  {
    index: 28,
    label: 'Помощь',
    customIcon: 'icon-question-circle-menu',
    to: '/w27',
    disabled: true,
    langKey: 'help'
  }
];

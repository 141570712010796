import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
  getDocumentationData
} from './actions';
import {
  DrillingOperationStatusType,
} from './types';
import { ChartLine } from '../../sdk/lineChart/types';
import { generateLine } from './convertor';

const { name } = options;

type DocumentationState = {
  isLoading: boolean;
  error: string;
  dateError: string;
  allPageLoading: boolean;
  drillingOperationChart: ChartLine[];
  documentationData: DrillingOperationStatusType[]
};

const initialState: DocumentationState = {
  isLoading: false,
  error: '',
  dateError: '',
  allPageLoading: false,
  documentationData: [],
  drillingOperationChart: [],
};

export const documentationSlice = createSlice({
  name,
  initialState,
  reducers: {

  },
  extraReducers: {
    [getDocumentationData.pending.type]: (state) => {
      state.isLoading = true;
      state.allPageLoading = true;
    },
    [getDocumentationData.fulfilled.type]: (state, { payload }: PayloadAction<DrillingOperationStatusType[]>) => {
      state.isLoading = false;
      state.dateError = '';
      state.documentationData = payload || null;
      state.drillingOperationChart = generateLine(payload);
    },
    [getDocumentationData.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.dateError = action.payload;
    },
  }
});

export default documentationSlice.reducer;
